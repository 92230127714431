<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <CardRegistrationTable
          :data="table1Data"
          :columns="lang == 'uz' ? table1Columns : table1ColumnsRu"
          @refreshData="refreshData"
        ></CardRegistrationTable>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import axios from "../axios.js";
// "Authors" table component.
import CardRegistrationTable from "../components/Users/CardRegistrationTable";

// "Authors" table list of columns and their properties.
const table1Columns = [
  {
    title: "USER ID",
    dataIndex: "userId",
  },
  {
    title: "RAQAMI / NOMI",
    dataIndex: "number",
  },
  {
    title: "TAHRIRLASH / O'CHIRISH",
    scopedSlots: { customRender: "editBtn" },
    width: 300,
  },
];
const table1ColumnsRu = [
  {
    title: "USER ID",
    dataIndex: "userId",
  },
  {
    title: "НОМЕР / ИМЯ",
    dataIndex: "number",
  },
  {
    title: "РЕДАКТИРОВАНИЕ / УДАЛЕНИЕ",
    scopedSlots: { customRender: "editBtn" },
    width: 300,
  },
];
export default {
  components: {
    CardRegistrationTable,
  },
  data() {
    return {
      lang: null,
      // Associating "Authors" table data with its corresponding property.
      table1Data: [],

      // Associating "Authors" table columns with its corresponding property.
      table1Columns: table1Columns,
      table1ColumnsRu: table1ColumnsRu,
    };
  },
  mounted() {
    this.getData();
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
  },
  methods: {
    refreshData() {
      this.getData();
    },
    getData() {
      axios
        .get("/api/registrations?size=700&sort=createdAt,desc")
        .then((res) => {
          // res.data._embedded.patients.forEach((item) => {
          //   let data = {
          //     key: "1",
          //     avatar: {
          //       photo: "images/face-3.jpg",
          //     },
          //     first_name: item.firstName,
          //     last_name: item.lastName,
          //     surname: item.middleName,
          //     sex: item.gender == 0 ? "ERKAK" : "AYOL",
          //     birth_date: item.birthDate,
          //     address: item.address,
          //     status: 1,
          //   };
          // });
          // console.log(res);
          this.table1Data = res.data._embedded.registrations;
        });
    },
  },
};
</script>

<style lang="scss">
</style>